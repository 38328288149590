import { Link } from '@tiptap/extension-link'

const LinkWithStyle = Link.extend({
    addAttributes() {
        return {
            ...this.parent?.(),
            style : {
                default: null,
                parseHTML: element => element.getAttribute('style'),
                renderHTML: attributes => {
                    if (!attributes.style) return {}
                    return { style: attributes.style }
                },
            },
        }
    },
})

export default LinkWithStyle;