import { Heading } from '@tiptap/extension-heading';
// import { mergeAttributes } from '@tiptap/core';

const CustomHeading = Heading.extend({
    // Définissez l'attribut `id`
    addAttributes() {
        return {
            id: {
                default: null,
                parseHTML: element => element.getAttribute('id'), // Récupère l'id à partir du DOM
                renderHTML: attributes => {
                    if (!attributes.id) {
                        return {}
                    }
                    return {
                        id: attributes.id // Applique l'id au DOM lors du rendu
                    }
                },
            },
        }
    },
})

export default CustomHeading;