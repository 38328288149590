import { BulletList } from '@tiptap/extension-bullet-list';
import { ListItem } from '@tiptap/extension-list-item';

const CustomBulletList = BulletList.extend({
    addAttributes() {
        return {
            style: {
                default: null,
                parseHTML: element => element.getAttribute('style'), // Récupère le style à partir du DOM
                renderHTML: attributes => {
                    if (!attributes.style) {
                        return {}
                    }
                    return {
                        style: attributes.style // Applique le style au DOM lors du rendu
                    }
                },
            },
        };
    },
});

const CustomListItem = ListItem.extend({
    addAttributes() {
        return {
            style: {
                default: null,
                parseHTML: element => element.getAttribute('style'), // Récupère le style à partir du DOM
                renderHTML: attributes => {
                    if (!attributes.style) {
                        return {}
                    }
                    return {
                        style: attributes.style // Applique le style au DOM lors du rendu
                    }
                },
            },
        };
    },
});

// Exports
export { CustomBulletList, CustomListItem };