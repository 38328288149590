import { Node } from '@tiptap/core';

// Extension de tableau pour conserver le style inline
const CustomTable = Node.create({
    name: 'table',

    group: 'block',

    content: 'tableHeader? tableRow+',

    parseHTML() {
        return [{
            tag: 'table',
            getAttrs: (node) => {
                return {
                    style: node.getAttribute('style') || '', // Garder les styles du tableau
                };
            },
        }, ];
    },

    renderHTML({ HTMLAttributes }) {
        return ['table', HTMLAttributes, 0];
    },

    addAttributes() {
        return {
            style: {
                default: null,
                parseHTML: (element) => element.getAttribute('style') || null,
                renderHTML: (attributes) => {
                    if (!attributes.style) return {};
                    return { style: attributes.style };
                },
            },
        };
    },
});

// Extension pour le header du tableau
const TableHeader = Node.create({
    name: 'tableHeader',

    content: 'tableRow+',

    parseHTML() {
        return [{
            tag: 'thead',
            getAttrs: (node) => {
                return {
                    style: node.getAttribute('style') || '', // Garder les styles du thead
                };
            },
        }, ];
    },

    renderHTML({ HTMLAttributes }) {
        return ['thead', HTMLAttributes, 0];
    },

    addAttributes() {
        return {
            style: {
                default: null,
                parseHTML: (element) => element.getAttribute('style') || null,
                renderHTML: (attributes) => {
                    if (!attributes.style) return {};
                    return { style: attributes.style };
                },
            },
        };
    },
});

// Extension pour la ligne de tableau (tr)
const TableRow = Node.create({
    name: 'tableRow',

    content: 'tableCell*',

    parseHTML() {
        return [{
            tag: 'tr',
            getAttrs: (node) => {
                return {
                    style: node.getAttribute('style') || '', // Garder les styles du tr
                };
            },
        }, ];
    },

    renderHTML({ HTMLAttributes }) {
        return ['tr', HTMLAttributes, 0];
    },

    addAttributes() {
        return {
            style: {
                default: null,
                parseHTML: (element) => element.getAttribute('style') || null,
                renderHTML: (attributes) => {
                    if (!attributes.style) return {};
                    return { style: attributes.style };
                },
            },
        };
    },
});

// Extension pour les cellules de tableau (td / th)
const TableCell = Node.create({
    name: 'tableCell',

    content: 'inline*',

    attrs: {
        isHeader: { default: false },
    },

    parseHTML() {
        return [{
                tag: 'td',
                getAttrs: (node) => {
                    return {
                        style: node.getAttribute('style') || '', // Garder les styles du td
                    };
                },
            },
            {
                tag: 'th',
                getAttrs: (node) => {
                    return {
                        isHeader: true,
                        style: node.getAttribute('style') || '', // Garder les styles du th
                    };
                },
            },
        ];
    },

    renderHTML({ node, HTMLAttributes }) {
        const tagName = node.attrs.isHeader ? 'th' : 'td';
        return [tagName, HTMLAttributes, 0];
    },

    addAttributes() {
        return {
            style: {
                default: null,
                parseHTML: (element) => element.getAttribute('style') || null,
                renderHTML: (attributes) => {
                    if (!attributes.style) return {};
                    return { style: attributes.style };
                },
            },
            isHeader: {
                default: false,
                parseHTML: (element) => element.tagName === 'TH',
                renderHTML: () => {},
            },
        };
    },
});

export { CustomTable, TableHeader, TableRow, TableCell };