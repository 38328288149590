<template>
    <v-dialog v-model="closeData.model" width="auto">
        <v-card>
            <v-col align=center>
                <div class="my-2" style="font-weight: bold;">
                    {{ closeData.label }}
                </div>
                <v-row>
                    <v-col>
                        <v-btn class="button_armo" style="background-color: red; color: white;"
                            @click="closeData.model = false">
                            Non
                        </v-btn>
                    </v-col>
                    <v-col>
                        <v-btn class="button_armo" style="background-color: green; color: white;"
                            @click="closeData.callback">
                            OUi
                        </v-btn>
                    </v-col>
                </v-row>
            </v-col>
        </v-card>
    </v-dialog>
    <v-col v-if="localGroupInformation && localGroupInformation.web_site_data">
        <v-btn icon="mdi-close" style="position: fixed;right: 15px;top:15px; z-index: 2;" density="compact" color="red"
            @click="closeModalVerif"></v-btn>
        <v-col style="color:white;font-size: 25px; font-weight: bold;" align="center" class="mb-2 pt-1">Gestion de votre
            Jauris</v-col>
        <v-row style="background-color: var(--C4);" v-if="localGroupInformation">
            <v-col
                v-if="localGroupInformation && localGroupInformation.web_site_data && Object.keys(localGroupInformation.web_site_data).length > 1"
                class="pb-0">
                <v-card class="bg pa-1">
                    <v-select bg-color="white" label="Platform"
                        :items="Object.keys(localGroupInformation.web_site_data)" v-model="selectedPlatform"
                        hide-details>
                    </v-select>
                </v-card>
            </v-col>

            <v-col class="mb-2 cms-panel" style="overflow: auto;" cols="12">
                <!-- <v-col style="font-weight: bold;" class="pb-4 pt-2 pl-1">
                    Mes sites :
                </v-col> -->
                <!-- <v-col class="pl-2">
                    <v-row justify="left">
                        <v-col cols="auto" class="px-1">
                            <v-span style="font-weight: bold;border-radius: 8px;" class="pa-2 mandatory">
                                Obligatoire
                            </v-span>
                        </v-col>
                        <v-col cols="auto" class="px-1">
                            <v-span style="font-weight: bold;border-radius: 8px;" class="pa-2 optional">
                                Conseiller
                            </v-span>
                        </v-col>
                        <v-col cols="auto" class="px-1">
                            <v-span style="font-weight: bold;border-radius: 8px;" class="pa-2 not-mandatory">
                                Optionnel
                            </v-span>
                        </v-col>
                    </v-row>
                </v-col> -->
                <v-expansion-panels v-model="panelOpened">
                    <v-expansion-panel v-for="(info, i) in localGroupInformation.web_site_data[selectedPlatform]"
                        :key="i">
                        <v-expansion-panel-title class="bg" style="color:white">
                            <template v-slot:default="{}">
                                <v-row no-gutters>
                                    <v-col class="d-flex justify-start" cols="4">
                                        {{ info.label }}
                                    </v-col>
                                </v-row>
                            </template>
                        </v-expansion-panel-title>
                        <v-expansion-panel-text>
                            <v-col cols="12" class="pa-0">
                                <v-card style="border-radius: 0px;" align="left" variant="flat" class="bg">
                                    <div style="position: absolute; right: 10px; top: 10px;">
                                        <v-icon style="cursor: pointer;" color="red"
                                            @click="removeBusiness(selectedPlatform, i)">mdi-minus-circle</v-icon>
                                    </div>
                                    <v-col>
                                        <v-row>
                                            <v-col v-for="subKey in keyToShow" :key="subKey" cols="12" class="pa-2">
                                                <v-card v-if="subKey.key == 'words_liste'" class="pa-2">
                                                    <v-col cols="12" class="pl-0" style="font-weight: bold">
                                                        Mots clés principaux<span style="color: grey;font-size: 12px;">
                                                            (Il
                                                            est
                                                            conseillé
                                                            de définir au minimum 5 mots clés)</span>
                                                    </v-col>
                                                    <v-divider class="mb-3"></v-divider>
                                                    <v-col v-if="info[subKey.key]" class="px-2 pb-2">
                                                        <v-row justify="left" align="left" class="">
                                                            <v-col v-for="(tags, idx) in info[subKey.key].split(',')"
                                                                :key="idx" class="py-1 px-0" cols="auto">
                                                                <v-chip class="mx-1"
                                                                    v-if="tags.replaceAll(' ', '').length != 0"
                                                                    style="cursor: pointer; border-radius:0px"
                                                                    @click="removeTag(selectedPlatform, i, info[subKey.key], idx)">
                                                                    {{ tags }} <v-icon
                                                                        color="red">mdi-close-thick</v-icon>
                                                                </v-chip>
                                                            </v-col>
                                                        </v-row>
                                                    </v-col>
                                                    <v-col>
                                                        <v-row align="center">
                                                            <v-col cols="" class="px-0">
                                                                <v-card :class="subKey.class" variant="flat">
                                                                    <v-text-field label="Ajouter des mots clés princip"
                                                                        v-model="newTag[selectedPlatform]" style=""
                                                                        hide-details class=""></v-text-field>
                                                                </v-card>
                                                            </v-col>
                                                            <v-col cols="auto">
                                                                <v-btn height="55px" variant="flat" class="button"
                                                                    style="font-weight: bold;"
                                                                    @click="addTag(selectedPlatform, i, info[subKey.key])">
                                                                    Ajouter
                                                                </v-btn>
                                                            </v-col>
                                                        </v-row>
                                                    </v-col>
                                                </v-card>
                                                <v-card v-else-if="subKey.key == 'link_connection'" variant="flat"
                                                    style="border: solid 1px lightgray; border-radius: 5px;"
                                                    class="pa-2">
                                                    <v-col cols="12" style="font-weight: bold" class="pl-0">
                                                        Gestion des liens
                                                    </v-col>
                                                    <v-divider class="mb-2"></v-divider>
                                                    <div>
                                                        <v-col>
                                                            <v-item-group mandatory v-model="selectedLinkType">
                                                                <v-row>
                                                                    <v-col v-for="linktype in linkData"
                                                                        :key="linktype.value" :cols="linktype.cols"
                                                                        class="px-0" @click="selectedLinkType = linktype.value">
                                                                        <v-item v-slot="{ isSelected, toggle }">
                                                                            <v-btn variant="flat"
                                                                                class="d-flex align-center"
                                                                                :style="isSelected ? 'background:var(--BG);color:white;' : 'background-color:#ECEFF1'"
                                                                                style="width:100%;text-transform: none !important;border-radius: 0%;"
                                                                                @click="toggle">
                                                                                {{ linktype.label }}
                                                                                <div v-if="linktype.icon">
                                                                                    <BasicHoverInformation
                                                                                        position="left"
                                                                                        :information="linktype.info">
                                                                                        <template v-slot:default>
                                                                                            <v-icon
                                                                                                :color="linktype.color"
                                                                                                :size="linktype.size">
                                                                                                {{
                                                                                                    linktype.icon }}
                                                                                            </v-icon>
                                                                                        </template>
                                                                                    </BasicHoverInformation>
                                                                                </div>
                                                                            </v-btn>
                                                                        </v-item>
                                                                    </v-col>
                                                                </v-row>
                                                            </v-item-group>
                                                        </v-col>
                                                        <v-col class="pa-0">
                                                            <!-- <v-checkbox
                                                                v-if="info && linkData[selectedLinkType] && linkData[selectedLinkType].keyToShow"
                                                                density="compact" class="pb-2"
                                                                style="font-weight: bold;"
                                                                :label="'Activer la correspondance automatique de lien ' + linkData[selectedLinkType].keyToShow"
                                                                hide-details
                                                                v-model="info.use_link_connection[linkData[selectedLinkType].key]"></v-checkbox> -->
                                                            <v-card class="mb-4 bg pa-1" variant="flat"
                                                                v-if="internLinkData[selectedPlatform + i] && linkData[selectedLinkType].value == 0 && internLinkData[selectedPlatform + i].urlListeByType">
                                                                <v-autocomplete bg-color="white" hide-details
                                                                    label="Filtrer par catégorie"
                                                                    :items="Object.keys(internLinkData[selectedPlatform + i].urlListeByType)"
                                                                    v-model="selectedKeysToShow">
                                                                </v-autocomplete>
                                                            </v-card>
                                                        </v-col>
                                                        <v-col class="px-0">
                                                            <div style="overflow: auto; width: 100%;white-space: nowrap"
                                                                class="">
                                                                <table
                                                                    style="color:white; text-overflow: clip; width: 100%;border: solid 1px white; background: var(--BG);"
                                                                    class="pt-3 pa-2">
                                                                    <tr align="center">
                                                                        <th style="width: 30%;" class="pb-3"
                                                                            v-if="selectedLinkType != 2 && selectedLinkType != 3">
                                                                            <div align=center>
                                                                                Ancre de lien
                                                                                <!-- <span style="font-size: 12px;">
                                                                                (Mot ou expression sur lequel vous placez votre lien)</span> -->
                                                                            </div>
                                                                        </th>
                                                                        <th class="pb-3">
                                                                            <div align=center>
                                                                                URL de destination
                                                                            </div>
                                                                        </th>
                                                                        <th style="width: 20%;" class="pb-3"
                                                                            v-if="selectedLinkType == 0 || selectedLinkType == 1">
                                                                            <div align=center>
                                                                                REL<span style="font-size: 12px;">
                                                                                    (indique aux moteurs de recherche de
                                                                                    suivre/ne pas suivre un
                                                                                    lien)</span>
                                                                            </div>
                                                                        </th>
                                                                        <th style="">
                                                                            <div align=center>
                                                                            </div>
                                                                        </th>
                                                                    </tr>
                                                                    <!-- <tr v-for="(elem2, idx2) in info.link_connection.filter((val) => val.value == selectedLinkType)" -->
                                                                        <tr v-for="(elem2, idx2) in info.link_connection"
                                                                        :key="idx2">
                                                                        <td
                                                                            v-if="selectedLinkType != 2 && selectedLinkType != 3">
                                                                            <v-text-field v-model="elem2.key"
                                                                                hide-details menu-icon=""
                                                                                bg-color="white"
                                                                                style="border-radius:0px !important;width: 100%; overflow: auto;">
                                                                            </v-text-field>
                                                                        </td>
                                                                        <td>
                                                                            <v-text-field v-if="selectedLinkType != 0"
                                                                                v-model="elem2.url" hide-details
                                                                                menu-icon="" bg-color="white"
                                                                                style="border-radius:0px !important;width: 100%; overflow: auto;">
                                                                            </v-text-field>
                                                                            <div
                                                                                v-else-if="internLinkData[selectedPlatform + i] && internLinkData[selectedPlatform + i].finalUrlData && selectedKeysToShow">
                                                                                <v-autocomplete menu-icon=""
                                                                                    v-model="elem2.url" bg-color="white"
                                                                                    style="border-radius:0px !important;width: 100%; overflow: auto;"
                                                                                    :items="internLinkData[selectedPlatform + i].finalUrlData.filter((val) => haveCommonElement(val.keys, selectedKeysToShow))"
                                                                                    hide-details>
                                                                                    <template
                                                                                        v-slot:item="{ props, item }">
                                                                                        <v-list-item v-bind="props">
                                                                                            {{ item.value.url }}
                                                                                        </v-list-item>
                                                                                    </template>
                                                                                </v-autocomplete>
                                                                            </div>
                                                                        </td>
                                                                        <td
                                                                            v-if="selectedLinkType == 0 || selectedLinkType == 1">
                                                                            <v-select :items="relListe"
                                                                                v-model="elem2.rel" item-value="value"
                                                                                item-title="label" hide-details
                                                                                bg-color="white"
                                                                                style="border-radius:0px !important;width: 100%; overflow: auto;">
                                                                            </v-select>
                                                                        </td>
                                                                        <td style="background-color: white;">
                                                                            <div align=center class="px-2">
                                                                                <v-btn color="red"
                                                                                    style="cursor:pointer;"
                                                                                    class="button_armo" icon
                                                                                    @click="removeLink(info[subKey.key], elem2)">
                                                                                    <v-icon>
                                                                                        mdi-trash-can
                                                                                    </v-icon>
                                                                                </v-btn>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                </table>
                                                            </div>
                                                            <v-col cols="12" align=right class=" pb-0 px-0">
                                                                <v-btn @click="addLink(info[subKey.key])" variant="flat"
                                                                    class="button">
                                                                    <div style="font-size: 25px;" class="">
                                                                        +
                                                                    </div> Ajouter un lien
                                                                </v-btn>
                                                            </v-col>
                                                        </v-col>
                                                    </div>
                                                </v-card>
                                                <v-card v-else-if="subKey.key == 'photo_management'"
                                                    :class="subKey.class" class="pa-3" variant="flat"
                                                    style="border-radius: 10px;">
                                                    <v-col cols="12" class="pl-0" style="font-weight: bold">
                                                        Génération d'image
                                                    </v-col>
                                                    <v-divider class="mb-2"></v-divider>
                                                    <v-col class="px-0 pb-1">
                                                        <!-- <v-card class="bg">
                                                            <v-select @update:modelValue="setNewPhotoParam" v-model="" :items="info[subKey.key]" item-title="label" hide-details style="color:white"></v-select>
                                                        </v-card> -->
                                                        <v-row>
                                                            <v-col
                                                                v-for="(elem2, idx2) in info[subKey.key].filter((val) => val.show == true)"
                                                                :key="idx2" align="center" cols="12" class="py-2">
                                                                <v-card
                                                                    @click="setNewPhotoParam(info[subKey.key], elem2)"
                                                                    variant="flat" color="#EDEDED" class="pa-5"
                                                                    height="100%"
                                                                    :style="elem2.selected ? 'background:var(--BG);color:white;' : ''">
                                                                    <h4>
                                                                        {{ elem2.label }}
                                                                    </h4>
                                                                </v-card>
                                                            </v-col>
                                                        </v-row>
                                                    </v-col>
                                                </v-card>
                                                <v-card v-else :class="subKey.class" style="border-radius: 0px;"
                                                    variant="flat">
                                                    <v-text-field :label="subKey.label" v-model="info[subKey.key]"
                                                        hide-details>
                                                    </v-text-field>
                                                </v-card>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                </v-card>
                            </v-col>
                        </v-expansion-panel-text>
                    </v-expansion-panel>
                </v-expansion-panels>
                <!-- <v-col v-if="selectedPlatform" cols="12" align=center class="">
                    <v-icon size="50" color="green" style="cursor: pointer;" @click="addBusiness(selectedPlatform)">
                        mdi-plus-circle
                    </v-icon>
                </v-col> -->
            </v-col>
            <v-col align=center class="pt-2">
                <v-btn variant="flat" class="button" @click="updateConfig()">
                    Enregistrer
                </v-btn>
            </v-col>
        </v-row>
    </v-col>
</template>

<script>

import engine from '@/core/index'

export default {
    data() {
        return {
            closeData: {},
            panelOpened: 0,
            selectedPlatform: undefined,
            relListe: [
                {
                    value: 'dofollow',
                    label: 'dofollow',
                    description: ' Il indique à Google de faire le lien entre votre page et l\'URL indiquée. Dès lors, il va être pris en compte dans l\'algorithme de Google',
                },
                {
                    value: 'nofollow',
                    label: 'nofollow',
                    description: 'une balise utilisée pour les liens vers du contenu non approuvé. Cette balise est utilisée quand on ne peut pas appliquer Sponsored et UGC.',
                },
                {
                    value: 'ugc',
                    label: 'ugc',
                    description: 'une balise utilisée pour les liens vers du contenu généré par les utilisateurs : commentaires, avis, posts, etc',
                },
                {
                    value: 'sponsored',
                    label: 'sponsored',
                    description: 'une balise utilisée pour les liens vers du contenu sponsorisé et payant.',
                },
            ],
            selectedKeysToShow: [],
            selectedLinkType: 0,
            internLinkData: {},
            newTag: {},
            localUsernformation: {},
            localBlogListe: {},
            localGroupInformation: {},
        }
    },
    props: {
        closeModal: { type: Function },
        // closeConfiguration: { type: Function },
        getLinksFormInformation: {},
        updateUserData: { type: Function },
        userInformation: {},
        groupInformation: { require: true },
        blogListe: {},
        sendNewConfig: { type: Function }
    },
    mounted() {
        this.localGroupInformation = JSON.parse(JSON.stringify(this.groupInformation))
        this.localBlogListe = JSON.parse(JSON.stringify(this.blogListe))
        this.localUsernformation = JSON.parse(JSON.stringify(this.userInformation))

        // :items="Object.keys(localGroupInformation.web_site_data)" v-model="selectedPlatform"

        if (this.localGroupInformation && this.localGroupInformation.web_site_data)
            this.selectedPlatform = Object.keys(this.localGroupInformation.web_site_data)[0]

        for (const [key, value] of Object.entries(this.localGroupInformation.web_site_data)) {
            for (let i = 0; i < value.length; i++) {
                this.getPageListeFromDomaine(value[i], key + i)
            }
        }

    },
    computed: {
        keyToShow() {
            let keys = []

            // let keys = [
            //     //     {
            //     //     label: 'Nom de la société',
            //     //     key: 'label',
            //     //     class: 'mandatory',
            //     // }, {
            //     //     label: 'URL du site web',
            //     //     key: 'url',
            //     //     class: 'mandatory',
            //     // }, {
            //     //     label: 'Thème principal',
            //     //     key: 'type',
            //     //     class: 'optional',
            //     // },
            //     // {
            //     //     label: 'Clé API',
            //     //     key: 'compte_token',
            //     //     class: 'not-mandatory',
            //     // }, 
            //     {
            //         label: 'words_liste',
            //         key: 'words_liste',
            //         class: 'optional',
            //     }, {
            //         label: 'Liens',
            //         key: 'link_connection',
            //     }, {
            //         label: 'Photo',
            //         key: 'photo_management',
            //     }
            // ]

            keys.push({
                label: 'words_liste',
                key: 'words_liste',
                class: 'optional',
            })
            if (this.linkData && this.linkData.length > 0)
                keys.push({
                    label: 'Liens',
                    key: 'link_connection',
                })
            keys.push({
                label: 'Photo',
                key: 'photo_management',
            })

            return keys
        },
        linkData() {
            let links = [
                {
                    label: "Liens internes",
                    value: 0,
                    key: 'interne',
                    keyToShow: 'interne',
                    cols: '',
                },
                {
                    label: "Liens externes",
                    value: 1,
                    key: 'externe',
                    keyToShow: 'externe',
                    cols: '',
                }, {
                    icon: 'mdi-block-helper',
                    label: "",
                    value: 2,
                    keyToShow: ' à bloqué',
                    key: 'blocked',
                    cols: 'auto',
                    color: 'red',
                    size: 20,
                    info: 'Liste des liens (domaine) qui ne doivent surtout pas être utilisé dans la génération d\'articles'
                }, {
                    icon: 'mdi-check-circle',
                    label: "",
                    value: 3,
                    keyToShow: ' à utiliser',
                    key: 'use',
                    cols: 'auto',
                    color: 'green',
                    size: 25,
                    info: 'Liste des liens (domaine) qui doivent être mis en avant et utiliser dans la génération d\'articles'
                }
            ]

            let filteredLink = []

            if (this.getLinksFormInformation.value)
                for (let i = 0; i < links.length; i++) {
                    if (this.getLinksFormInformation.value.find((val) => val.value == links[i].value))
                        filteredLink.push(links[i])
                }
            else
                return []

            return filteredLink
        },
        isMobile() {
            return this.$vuetify.display.mobile
        },
    },
    methods: {
        closeModalVerif() {
            let that = this
            this.setCloseModal(
                'Aucune modification ne sera sauvegardé',
                function () {
                    console.log("close");
                    that.closeModal()
                }
            )
        },
        setCloseModal(label, callback) {
            this.closeData = {
                label,
                model: true,
                callback
            }
        },
        setNewPhotoParam(elem, elem2) {
            console.log("setNewPhotoParam", elem, elem2);

            for (let i = 0; i < elem.length; i++) {
                elem[i].selected = false
            }
            elem2.selected = true
        },
        removeLink(allLinks, linkTab) {
            let pos = allLinks.findIndex((val) => val.key == linkTab.key && val.label == linkTab.label && val.url == linkTab.url)
            allLinks = allLinks.splice(pos, 1)
        },
        addLink(linkTab) {
            console.log("addLink", linkTab);
            
            let localLinkData = this.linkData[this.selectedLinkType]
            linkTab.push({ key: undefined, url: undefined, rel: 'nofollow', label: localLinkData.label, value: localLinkData.value })
        },
        getPageListeFromDomaine(site, mainKey) {
            console.log("getPageListeFromDomaine", mainKey, site);

            // let site = this.localBlogListe[val]
            let that = this
            let finalUrlData = []
            let finalKeyListe = []
            let urlListeByType = {}

            console.log("site", site);

            if (site) {
                engine.get_url_liste_from_domaine(site.url, function (data) {
                    console.log("data", data);

                    if (data.state == 200) {
                        let urlListe = data.data
                        for (let i = 0; i < urlListe.length; i++) {
                            let url = urlListe[i]
                            let keyUrl = urlListe[i].split(site.url)[1]
                            if (url != null && url.includes('/')) {
                                let keyListe = keyUrl.split('/').filter((val) => val.length > 0)
                                if (keyListe.length >= 2) {
                                    let long = keyListe.length
                                    let key = keyListe[long - 2]
                                    let value = keyListe[long - 1]
                                    if (urlListeByType[key] == undefined)
                                        urlListeByType[key] = []
                                    urlListeByType[key].push(value)
                                }
                                finalUrlData.push({
                                    title: keyUrl,
                                    value: url,
                                    keys: keyListe
                                })
                            }
                        }
                        that.internLinkData[mainKey] = { finalKeyListe, finalUrlData, urlListeByType }
                    } else {
                        that.internLinkData[mainKey] = { finalKeyListe, finalUrlData, urlListeByType }
                    }
                })

            }
            else
                that.internLinkData[mainKey] = { finalKeyListe, finalUrlData }
        },
        haveCommonElement(arr1, arr2) {
            if (arr2.length == 0)
                return true
            if (arr1.includes(arr2))
                return true
            return false
            // Créer un ensemble à partir du premier tableau
            // const set1 = new Set(arr1);

            // if (arr2.length == 0)
            //     return true
            // // Parcourir le deuxième tableau pour vérifier les éléments en commun
            // for (const elem of arr2) {
            //     if (set1.has(elem)) {
            //         return true;
            //     }
            // }

            // // Si aucun élément en commun n'est trouvé
            // return false;
        },
        addTag(key, index, localBlogListe) {
            // let findedBlog = this.localBlogListe.find((val) => val.id == blog.id && val.label == blog.label)
            let tmpListe = localBlogListe.split(',')
            tmpListe.push(this.newTag[key])
            tmpListe = tmpListe.join(',')
            this.localGroupInformation.web_site_data[key][index].words_liste = tmpListe
        },
        removeTag(key, index, localBlogListe, selectedTagIdx) {
            let tmpListe = localBlogListe.split(',')
            tmpListe.splice(selectedTagIdx, 1);
            tmpListe = tmpListe.join(',')
            this.localGroupInformation.web_site_data[key][index].words_liste = tmpListe
        },
        updateConfig() {
            let that = this
            this.setCloseModal(
                'Valider mes modifications ?',
                function () {
                    that.sendNewConfig(that.localGroupInformation)
                    that.closeModal()
                }
            )

        },
        removeBusiness(key, index) {
            this.localGroupInformation.web_site_data[key].splice(index, 1)
        },
        addBusiness(key) {
            this.localGroupInformation.web_site_data[key].push({})
        },
        close() {
            this.$emit('close')
        }
    }
}
</script>

<style></style>