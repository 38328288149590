import axios from 'axios'
var CryptoJS = require("crypto-js");

class openIaServer {
    constructor() {
        this.url = "https://" + process.env.VUE_APP_USE_URL + "/"
        this.cryptageKey = "kujlhsiqsd654f87j64jù(')=às684g84jhg^pld)=à('rrffds"

        console.log('env', process.env)
    }

    encrypt(data) {
        data['server_token'] = "kujlhsiqsd654f87j64jù(')=às684g84jhg^pld)=à('rrffds"
        var encryptedData = CryptoJS.AES.encrypt(JSON.stringify(data), this.cryptageKey).toString();
        return { encryptedData }
    }

    decrypt(data) {
        let bytes = CryptoJS.AES.decrypt(data, this.cryptageKey)
        let decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8))
        return decryptedData
    }

    async get_image_from_dall_E(description, callback) {
        let data = { description }
        await axios.post(this.url + "get_image_from_dall_E", this.encrypt(data))
            .then((res) => {
                callback(res.data)
            })
    }

    async get_image_variations_from_dall_E(path, callback) {
        let data = { path }
        await axios.post(this.url + "get_image_variations_from_dall_E", this.encrypt(data))
            .then((res) => {
                callback(res.data)
            })
    }

    async get_openAi_thread(callback) {
        let data = {}
        await axios.post(this.url + "get_openAi_thread", this.encrypt(data))
            .then((res) => {
                callback(res.data)
            })
    }

    async get_answer_from_chatgpt(question, assistant_id, thread_id, callback) {
        let data = { question, assistant_id, thread_id }
        await axios.post(this.url + "get_answer_from_chatgpt", this.encrypt(data))
            .then((res) => {
                callback(res.data)
            })
    }

    async edit_instagram_post_by_id(postData, callback) {
        let data = { postData, token: localStorage.getItem('token') }
        await axios.post(this.url + "edit_instagram_post_by_id", this.encrypt(data))
            .then((res) => {
                callback(res.data)
            })
    }

    async get_picture_from_by_path(path, callback) {
        let data = { path, token: localStorage.getItem('token') }
        await axios.post(this.url + "get_picture_from_by_path", this.encrypt(data))
            .then((res) => {
                callback(res.data)
            })
    }

    async save_file_in_group_album(groupId, base64Liste, callback) {
        let headers = {
            'Content-Type': 'application/json',
            // Ajoutez d'autres en-têtes si nécessaire
        };

        // Objet de configuration Axios
        let config = {
            headers: headers,
        };

        let data = { groupId, base64Liste, token: localStorage.getItem('token') }
        await axios.post(this.url + "save_file_in_group_album", this.encrypt(data), config)
            .then((res) => {
                callback(res.data)
            })
    }

    async get_all_files_in_group_album(groupId, callback) {
        let data = { groupId, token: localStorage.getItem('token') }

        await axios.post(this.url + "get_all_files_in_group_album", this.encrypt(data))
            .then((res) => {
                callback(res.data)
            })
    }

    async add_to_squeduel(postData, callback) {
        let data = { postData, token: localStorage.getItem('token') }
        await axios.post(this.url + "add_to_squeduel", this.encrypt(data))
            .then((res) => {
                callback(res.data)
            })
    }

    async get_user_squeduel_by_token(callback) {
        let data = { token: localStorage.getItem('token') }
        await axios.post(this.url + "get_user_squeduel_by_token", this.encrypt(data))
            .then((res) => {
                callback(res.data)
            })
    }


    async is_user_token_valid(callback) {
        let token = localStorage.getItem('token')
        let data = { token }
        if (token == undefined || token == null || token.length == 0) {
            callback({ state: 400 })
        } else
            await axios.post(this.url + "is_user_token_valid", this.encrypt(data))
            .then((res) => {
                callback(res.data)
            })
    }

    async get_user_info_by_token(callback) {
        let data = { token: localStorage.getItem('token') }
        await axios.post(this.url + "get_user_info_by_token", this.encrypt(data))
            .then((res) => {
                callback(res.data)
            })
    }

    async get_group_by_user_id(group_id, callback) {
        let data = { token: localStorage.getItem('token'), group_id }
        await axios.post(this.url + "get_group_by_user_id", this.encrypt(data))
            .then((res) => {
                callback(res.data)
            })
    }

    async update_group_config(group_id, group_config, callback) {
        let data = { token: localStorage.getItem('token'), group_id, group_config }
        await axios.post(this.url + "update_group_config", this.encrypt(data))
            .then((res) => {
                callback(res.data)
            })
    }

    async edit_group_post_month(group_id, post_month, callback) {
        let data = { token: localStorage.getItem('token'), group_id, post_month }
        await axios.post(this.url + "edit_group_post_month", this.encrypt(data))
            .then((res) => {
                callback(res.data)
            })
    }

    async edit_group_post_month_max(group_id, post_month_max, callback) {
        let data = { token: localStorage.getItem('token'), group_id, post_month_max }
        await axios.post(this.url + "edit_group_post_month_max", this.encrypt(data))
            .then((res) => {
                callback(res.data)
            })
    }

    async edit_article_month_generated(group_id, article_month_generated, callback) {
        let data = { token: localStorage.getItem('token'), group_id, article_month_generated }
        await axios.post(this.url + "edit_article_month_generated", this.encrypt(data))
            .then((res) => {
                callback(res.data)
            })
    }

    async user_login(login_data, callback) {
        let data = { email: login_data.email, password: login_data.password }
        await axios.post(this.url + "user_login", this.encrypt(data))
            .then((res) => {
                callback(res.data)
            })
    }

    async user_subscribe(suscribe_data, callback) {
        let data = { suscribe_data }
        await axios.post(this.url + "user_subscribe", this.encrypt(data))
            .then((res) => {
                callback(res.data)
            })
    }

    async get_shopify_blog_liste(siteUrl, token, callback) {
        let data = { siteUrl, token }
        await axios.post(this.url + "get_shopify_blog_liste", this.encrypt(data))
            .then((res) => {
                callback(res.data)
            })
    }

    async update_album_data(albumData, group_id, callback) {
        let data = { albumData, group_id, token: localStorage.getItem('token') }
        await axios.post(this.url + "update_album_data", this.encrypt(data))
            .then((res) => {
                callback(res.data)
            })
    }

    async shopify_add_to_squeduel(postData, callback) {
        let data = { postData, token: localStorage.getItem('token') }
        await axios.post(this.url + "shopify_add_to_squeduel", this.encrypt(data))
            .then((res) => {
                callback(res.data)
            })
    }

    async creat_shopify_article_from_server(dataToServer, callback) {
        let data = { dataToServer, token: localStorage.getItem('token') }
        await axios.post(this.url + "creat_shopify_article_from_server", this.encrypt(data))
            .then((res) => {
                callback(res.data)
            })
    }

    async creat_instagram_publication_from_server(dataToServer, callback) {
        let data = { dataToServer, token: localStorage.getItem('token') }
        await axios.post(this.url + "creat_instagram_publication_from_server", this.encrypt(data))
            .then((res) => {
                callback(res.data)
            })
    }

    async edit_shopify_post_by_id(postData, callback) {
        let data = { postData, token: localStorage.getItem('token') }
        await axios.post(this.url + "edit_shopify_post_by_id", this.encrypt(data))
            .then((res) => {
                callback(res.data)
            })
    }


    async edit_article_from_id_by_key(articleId, key, value, callback) {
        let data = { articleId, key, value, token: localStorage.getItem('token') }
        await axios.post(this.url + "edit_article_from_id_by_key", this.encrypt(data))
            .then((res) => {
                callback(res.data)
            })
    }

    async edit_article_from_id_by_multie_keys(articleId, keysObj, callback) {
        let data = { articleId, keysObj, token: localStorage.getItem('token') }
        await axios.post(this.url + "edit_article_from_id_by_multie_keys", this.encrypt(data))
            .then((res) => {
                callback(res.data)
            })
    }

    async update_user_informations(userInformation, callback) {
        let data = { userInformation, token: localStorage.getItem('token') }
        await axios.post(this.url + "update_user_informations", this.encrypt(data))
            .then((res) => {
                callback(res.data)
            })
    }

    async update_user_by_key(userData, key, callback) {
        let data = { userData, key, token: localStorage.getItem('token') }
        await axios.post(this.url + "update_user_by_key", this.encrypt(data))
            .then((res) => {
                callback(res.data)
            })
    }

    async get_image_base64_from_url(url, callback) {
        let data = { url, token: localStorage.getItem('token') }
        await axios.post(this.url + "get_image_base64_from_url", this.encrypt(data))
            .then((res) => {
                callback(res.data)
            })
    }

    async photo_download(dropBoxPath, callback) {
        let datas = { dropBoxPath, token: localStorage.getItem('token') }

        await axios.post(this.url + 'photo_download', this.encrypt(datas))
            .then(res => {
                callback(res.data);
            });
    }

    async file_upload(file, dropBoxPath, callback) {
        let datas = { file, dropBoxPath, token: localStorage.getItem('token') }

        await axios.post(this.url + 'file_upload', this.encrypt(datas))
            .then(res => {
                callback(res.data);
            });
    }

    async file_delete(fileName, callback) {
        let datas = { fileName, token: localStorage.getItem('token') }

        await axios.post(this.url + 'file_delete', this.encrypt(datas))
            .then(res => {
                callback(res.data);
            });
    }

    async renameAlbumFile(oldFileName, newFileName, callback) {
        let datas = { oldFileName, newFileName, token: localStorage.getItem('token') }

        await axios.post(this.url + 'rename_album_file', this.encrypt(datas))
            .then(res => {
                callback(res.data);
            });
    }


    async getNewProposal(id, wordsListe, newArticleProposalPromt, assistantId, groupId, callback) {
        let datas = { id, wordsListe, newArticleProposalPromt, assistantId, groupId, token: localStorage.getItem('token') }

        await axios.post(this.url + 'getNewProposal', this.encrypt(datas))
            .then(res => {
                callback(res.data);
            });
    }

    async get_url_liste_from_domaine(url, callback) {
        let datas = { url, token: localStorage.getItem('token') }

        await axios.post(this.url + 'get_url_liste_from_domaine', this.encrypt(datas))
            .then(res => {
                callback(res.data);
            });
    }

    async google_authenticate(clientId, redirectUri, callback) {
        let datas = { clientId, redirectUri, token: localStorage.getItem('token') }

        await axios.post(this.url + 'google_authenticate', this.encrypt(datas))
            .then(res => {
                callback(res.data);
            });
    }

    async sendMail(to, obj, message, callback) {
        let datas = { to, obj, message }
        await axios.post(this.url + 'sendMail', this.encrypt(datas))
            .then(res => {
                callback(res.data);
            });
    }

    async get_basic_data(callback) {
        let data = {}
        await axios.post(this.url + "get_basic_data", this.encrypt(data))
            .then((res) => {
                callback(res.data)
            })
    }
}

export default new openIaServer()