<template>
    <v-dialog v-model="closeData.model" width="auto">
        <v-card>
            <v-col align=center>
                <div class="my-2" style="font-weight: bold;">
                    {{ closeData.label }}
                </div>
                <v-row>
                    <v-col>
                        <v-btn class="button_armo" style="background-color: red; color: white;"
                            @click="closeData.model = false">
                            Non
                        </v-btn>
                    </v-col>
                    <v-col>
                        <v-btn class="button_armo" style="background-color: green; color: white;"
                            @click="closeData.callback">
                            OUi
                        </v-btn>
                    </v-col>
                </v-row>
            </v-col>
        </v-card>
    </v-dialog>
    <v-card class="pa-2 pt-0 bg">
        <v-col>
            <v-btn @click="close()" style="position: absolute; right: 5px; top: 5px; z-index: 2;" size="30"
                icon="mdi-close" class="buttonReverse"></v-btn>
            <v-col>
                <v-col style="color:white;font-size: 32px">
                    Laissez un message à votre conseillé Max Costa !
                </v-col>
                <v-col style="color:white;font-size: 18px">
                    Jauris est en constante amélioration, et nous avons besoin de vos retours pour améliorer
                    l'expérience utilisateur jour après jour.
                </v-col>
                <!-- {{ groupInformation.id }}
                {{ groupInformation.name }} -->
                <!-- <v-col>
                    {{ userInformation.lastname }}
                    {{ userInformation.firstname }}
                </v-col> -->
                <v-col>
                    <v-text-field bg-color="white" variant="solo" v-model="message.mail"
                        label="Email de réponse"></v-text-field>
                    <v-text-field bg-color="white" variant="solo" v-model="message.subject"
                        label="Sujet"></v-text-field>
                    <v-textarea bg-color="white" variant="solo" v-model="message.content" auto-grow
                        label="Message"></v-textarea>
                </v-col>
                <v-col
                    v-if="message.mail && message.mail.length > 0 && message.subject && message.subject.length > 0 && message.content && message.content.length > 0"
                    cols="12" align="center" class="py-0">
                    <v-btn class="buttonReverse" @click="verifSendMail">Envoyer</v-btn>
                </v-col>
                <v-col v-else
                cols="12" align="center" class="py-0">
                    <v-btn disabled class="buttonReverse">L'ensemble des champs est obligatoire</v-btn>
                </v-col>
            </v-col>
        </v-col>
    </v-card>
</template>

<script>

import engine from '@/core/index'

export default {
    data() {
        return {
            message: {},
            closeData: {}
        }
    },
    mounted() {
        this.message.mail = this.userInformation.email
    },
    methods: {
        verifSendMail() {
            let that = this

            this.closeData = {
                model: true,
                label: 'Envoyer mon message ?',
                callback: that.sendMail
            }
        },
        sendMail() {
            let that = this

            let msg = 'Contact : ' + this.message.mail + '\n\nSujet : ' + this.message.subject + '\n\nMessage : ' + this.message.content

            engine.sendMail('contact@jauris.com', 'Message interne : ' + this.groupInformation.name, msg, function (data) {
                console.log("sendMail", data);
                that.close('Votre message à bien été envoyé !')
            })
        }
    },
    props: {
        close: { type: Function },
        userInformation: {},
        groupInformation: {},
    },
    computed: {
        isMobile() {
            return this.$vuetify.display.mobile
        },
    }
}
</script>