import { Node } from '@tiptap/core';

const CustomDiv = Node.create({
    name: 'div',

    group: 'block',

    content: 'block*',

    parseHTML() {
        return [{
            tag: 'div',
        }, ];
    },

    renderHTML({ HTMLAttributes }) {
        return ['div', HTMLAttributes, 0];
    },

    addAttributes() {
        return {
            id: {
                default: null,
                parseHTML: (element) => element.getAttribute('id'),
                renderHTML: (attributes) => {
                    if (!attributes.id) return {};
                    return { id: attributes.id };
                },
            },
            style: {
                default: null,
                parseHTML: (element) => element.getAttribute('style'),
                renderHTML: (attributes) => {
                    if (!attributes.style) return {};
                    return { style: attributes.style };
                },
            },
            class: {
                default: null,
                parseHTML: (element) => element.getAttribute('class'),
                    renderHTML: (attributes) => {
                        if (!attributes.class) return {};
                        return { class: attributes.class };
                    },
            },
        };
    },
});

export default CustomDiv;