import { Paragraph } from '@tiptap/extension-paragraph'

const ParagraphWithId = Paragraph.extend({
    addAttributes() {
        return {
            ...this.parent?.(),
            id : {
                default: null,
                parseHTML: element => element.getAttribute('id'),
                renderHTML: attributes => {
                    if (!attributes.id) return {}
                    return { id: attributes.id }
                },
            },
            href: {
                default: null,
                parseHTML: element => element.getAttribute('href'),
                renderHTML: attributes => {
                    if (!attributes.href) return {}
                    return { href: attributes.href }
                },
            },
            target: {
                default: null,
                parseHTML: element => element.getAttribute('target'),
                renderHTML: attributes => {
                    if (!attributes.target) return {}
                    return { target: attributes.target }
                },
            },
            style: {
                default: null,
                parseHTML: element => element.getAttribute('style'),
                renderHTML: attributes => {
                    if (!attributes.style) return {}
                    return { style: attributes.style }
                },
            },
        }
    },
})


export default ParagraphWithId;